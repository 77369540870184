<template>
  <div>
    <div class="newsList" v-if="list.length > 0">
      <div class="link" v-for="(item, index) in list" :key="index">
        <!-- <a href="javascript:void(0)"> -->
        <div class="item">
          <span>{{ item.title }}</span>
          <span>{{ item.createTime }}</span>
          <span @click="onDownload(item)">下载</span>
        </div>

        <!-- </a> -->
      </div>
    </div>
    <el-pagination
      :hide-on-single-page="total == 0"
      style="text-align: right; margin-top: 20px"
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <div class="not-data" v-if="list.length == 0">暂无数据</div>
  </div>
</template>

<script>
import { pagination } from "element-ui";
export default {
  components: {
    "el-pagination": pagination,
  },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: null,
    },
    pageNo: Number,
    pageSize: Number,
    total: Number,
    keyword: String,
    current: Number,
    detailsType: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("paging", val);
    },
    onDownload(item) {
      // window.location.href = item.url
      this.download(item.url, item.title);
    },
    download(url, fileName) {
      if (!url) {
        return;
      }
      let link = document.createElement("a"); //创建a标签
      link.style.display = "none"; //将a标签隐藏
      link.href = url; //给a标签添加下载链接
      link.setAttribute("download", fileName); // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
      document.body.appendChild(link);
      link.click(); //执行a标签
    },
  },
};
</script>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c01515;
  color: #fff;
}
</style>
<style lang="scss" scoped>
@import "./../../assets/style/public.scss";
.not-data {
  min-height: 472px;
  text-align: center;
  font-size: 16px;
  color: #888888;
  margin-top: 30px;
}
.newsList {
  @extend %clearfix;
  min-height: 472px;
  .link {
    width: 100%;
    height: 32px;
    float: left;
    margin-top: 20px;
    .item {
      font-size: 16px;
      color: #222222;
      line-height: 32px;
      display: block;
      span {
        &:first-child {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          float: left;
        }
        &:last-child {
          float: right;
          cursor: pointer;
          &:hover {
            // text-decoration: underline;
            color: #c01515;
          }
        }
      }
    }
  }
}
</style>
