<template>
  <div class="download">
    <Crumbs :breadList="crumbs" />
    <div class="main">
      <NewsList
        v-show="isShowList"
        :list="list"
        :pageNo="pageNo"
        :pageSize="pageSize"
        :total="total"
        :current="current"
        :detailsType="2"
        @paging="onPaging"
      />
    </div>
  </div>
</template>
<script>
import Crumbs from "@/components/crumbs/index";
import { getTreeItem } from "@/utils/index";
import NewsList from "@/components/newsList/download";
export default {
  components: {
    Crumbs,
    NewsList,
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      leftList: [],
      crumbs: [],
      menus: [],
      selectedIndex: 0,
      list: [],
      pageNo: 1,
      pageSize: 0,
      total: 0,
      current: 1,
      parentItem: null, // 父级对象
      childrenItem: null, // 子级对象
      isShowList: true,
      item: null, // 详情
      type: 0, // 1详情 还是以 0列表展示
      articleId: "",
      detailsId: null,
    };
  },
  methods: {
    init() {
      let myMenus = JSON.parse(sessionStorage.getItem("menus"));
      this.parentItem = getTreeItem(myMenus, this.$route.query.pid, "id");
      this.menus = this.parentItem.children; // 菜单
      let sid = this.$route.query.sid;
      if (sid) {
        this.childrenItem = getTreeItem(myMenus, this.$route.query.sid, "id");
        this.menus.forEach((item, index) => {
          if (item.id == this.childrenItem.id) {
            this.selectedIndex = index;
          }
        });
      } else {
        this.selectedIndex = 0;
      }
      console.log("this.parentItem.children --- ", this.parentItem.children);
      if (this.parentItem.children.length > 0) {
        this.type = this.menus[this.selectedIndex].type;
      } else {
        this.list = [];
        this.item = null;
        this.total = 0;
        this.pageSize = 0;
        this.current = 1;
      }
      this.getList();
      this.updateCrumbs(); // 构建面包
    },
    async getList() {
      let res = await this.$api.second.getDownList({
        pageNo: this.pageNo,
      });
      console.log("res ---- ", res);
      if (res.status == 200) {
        this.list = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
        this.current = res.data.current;
      }
    },
    updateCrumbs() {
      if (this.crumbs.length == 0) {
        this.crumbs.push({
          name: "首页",
          link: "/",
        });
        if (this.parentItem.children.length > 0) {
          this.crumbs.splice(1, 1, {
            name: this.parentItem.name,
            link:
              "/download?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
          });
          this.crumbs.splice(2, 1, {
            name: this.childrenItem.name,
            link: "",
          });
        } else {
          this.crumbs.splice(1, 2);
          this.crumbs.push({
            name: this.parentItem.name,
          });
        }
      } else {
        if (this.parentItem.children.length > 0) {
          this.crumbs.splice(1, 1, {
            name: this.parentItem.name,
            link:
              "/download?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
          });
          this.crumbs.splice(2, 1, {
            name: this.childrenItem.name,
            link: "",
          });
        } else {
          this.crumbs.splice(1, 2);
          this.crumbs.push({
            name: this.parentItem.name,
          });
        }
      }
    },
    onPaging(value) {
      this.pageNo = value;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped></style>
